import { Modal as BaseModal } from "../../../Components/Modal/Modal";
import { Button, LoaderIcon, Select, Spinner } from "../../../Components/UI";
import { useDenyOrderApi, useEditOrderApi } from "../../../Utilities/Api";
import { useEffect, useState } from "react";
import { useGlobalError } from "../../../Components/GlobalError/GlobalError";
import { useTranslation } from "react-i18next";
import { API_ENDPOINT } from "../../../Utilities/Constants";
import { useAuth } from "../../../Utilities/Auth/UseAuth";

type Props = {
  visible: boolean,
  values: Form | undefined,
  onClose: (refresh: boolean) => void
}

export type Form = {
  id: number,
  code: string,
  created_date: string,
  mail: string,
  phone: string,
  first_name: string,
  last_name: string,
  street: string,
  city: string,
  city_code: string,
  country: string,
  note: string,
  status:	string,
  prize: number,
  denied: number
};

const defaultValues = {
  id: 0,
  code: "",
  created_date: "",
  mail: "",
  phone: "",
  first_name: "",
  last_name: "",
  street: "",
  city: "",
  city_code: "",
  country: "",
  note: "",
  status:	"",
  prize: 0,
  denied: 0
};

export const Modal = ({ visible, onClose, values=defaultValues }: Props) =>
{
  const { t } = useTranslation("orders");
  const { setError } = useGlobalError();
  const {send: sendEdit, loading: loadingEdit} = useEditOrderApi();
  const {send: sendDeny, loading: loadingDeny} = useDenyOrderApi();
  const [form, setForm] = useState<Form>(defaultValues);
  const { accessToken } = useAuth();

  const updateForm = (newValues: Partial<Form>) => {
    setForm(v => ({ ...v, ...newValues }));
  };

  useEffect(() => {
    if(!visible) return;
    const newValues = values || defaultValues;
    updateForm(newValues);
  }, [visible, values]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    sendEdit({
      id: form.id,
      status: form.status
    })
      .then(() => onClose(true));
  };

  const deny = (v: "true" | "false") => {
    sendDeny({
      id: form.id,
      denied: v
    })
      .then(() => onClose(true));
  };

  const img = `${API_ENDPOINT}/get_upload.php?api_key=${accessToken}&id=${values.id}&location=orders`;

  return (
    <BaseModal 
      visible={visible} 
      title={t("modal.edit.title")} 
      onClose={() => onClose(false)}>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="column">
              {(["created_date", "mail", "phone", "first_name", "last_name", "street", "city", "city_code", "country"] as (keyof Form)[]).map(key => 
                <div className="formGroup" key={key}>
                  <label>{t(`modal.form.${key}.label`)}</label>
                  <div className="static">{values[key]}</div>
                </div>
              )}
              <div className="formGroup">
                <label>{t(`modal.form.note.label`)}</label>
                <div className="static multiLine">{values.note}</div>
              </div>
            </div>
            <div className="column">
              {/* <img src={img} alt="" onClick={() => { window.open(img, "_blank"); }}></img> */}
              <div className="formGroup half">
                <label>{t("modal.form.status.label")}</label>
                <Select 
                  value={form.status}
                  onChange={status => updateForm({ status })}
                  options={[ 
                    {
                      value: "", 
                      content: <div>{t(`unresolved`)}</div>
                    },
                    {
                      value: "resolved", 
                      content: <div>{t(`resolved`)}</div>
                    }
                  ]}/>
              </div>
              <div className="formGroup half">
                <Button
                  onClick={() => deny(form.denied ? "false" : "true")}
                  type="button"
                  mode="secondary"
                  color="danger"
                  size="large">
                  {form.denied ? t("modal.edit.cancel_deny") : t("modal.edit.deny")}
                </Button>
              </div>
            </div>
          </div>
          <div className="formGroup">
            <Button 
              size="large" 
              type="submit"
              icon={loadingEdit && <Spinner><LoaderIcon color='white'/></Spinner>}>
              {t("modal.edit.submit")}
            </Button>
          </div>
        </form>
    </BaseModal>
  );
}