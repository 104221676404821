import { useState } from 'react';
import { useDeleteLocationSuggestionApi, useGetLocationSuggestionsApi } from '../../Utilities/Api';
import Item from './Item/Item'

import './LocationSuggestions.scss';
import { EmptyList } from '../../Components/UI';
import { Form, Modal, defaultValues } from '../Locations/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { LocationSuggestion } from '../../Utilities/Api/Types/LocationSuggestion';

export default function LocationSuggestions()
{
  const { t } = useTranslation("locationSuggestions");
  const { response, refresh } = useGetLocationSuggestionsApi();
  const {send: sendDelete, loading: loadingDelete} = useDeleteLocationSuggestionApi();
  const [modal, setModal] = useState<Form>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);

  const handleClose = (shouldRefresh: boolean) => {
    setModalVisible(false);
    if(shouldRefresh) {
      sendDelete({ id }).then(() => {
        refresh();
      });
    }
  };

  const handleDelete = (item: LocationSuggestion) => {
    sendDelete({ id: item.id }).then(() => {
      refresh();
    });
  };

  const handleClick = (item: LocationSuggestion) => {
    setModalVisible(true);
    setId(item.id);
    fetch(`https://geocode.maps.co/search?q=${encodeURIComponent(item.address)}`)
      .then(r => r.json())
      .then(r => {
        const { lat, lon } = r[0];
        setModal({ ...defaultValues, lat, lng: lon, address: item.address });
      })
  };

  return (
    <div className="content">
      <header>
        <h2>{t('title')}</h2>
      </header>
      <div className="locationSuggestions">
        <div className="container">
        {response.locations.length === 0
            ? <EmptyList text={t('empty.text')}/>
            : response.locations.map((item, index) => 
              <Item 
                key={index}
                onClick={() => handleClick(item)}
                onDelete={() => handleDelete(item)}
                {...item} />
            )}
        </div>
      </div>
      <Modal visible={modalVisible} stores={response.stores} onClose={handleClose} values={modal} forceNew={true} />
    </div>
  );
}
