import { SummaryGroupSumup } from "../../../Utilities/Api";
import "./DisplaySumup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

type Props = SummaryGroupSumup

export default function DisplaySumupChart({ data }: Props)
{
  const { t } = useTranslation("summary");
  
  const total = data.groups.find(group => group.label === "total")?.values[0] || 0;
  const resolved = data.groups.find(group => group.label === "resolved")?.values[0] || 0;

  const savingsSaved = total - resolved === 0;

  const maxValue = Math.max(total, resolved);
  const heights = {
    total: total * 100 / maxValue,
    expenses: resolved * 100 / maxValue
  };

  return <div className="displaySumup">
    <div className="graph">
      <div className="bars">
        <div 
          className={"bar" + (heights.total < 35 ? " small" : "")} 
          style={{ height: heights.total + "%" }}>
          <div className="content">
            <div className="value">{total}</div> 
          </div>
          <div className="label">{t("displaySumup.total")}</div>
        </div>
        <div 
          className={"bar" + (heights.expenses < 35 ? " small" : "")}
          style={{ height: heights.expenses + "%" }}>
          <div className="content">
              <div className="value">{resolved}</div> 
            </div>
            <div className="label">{t("displaySumup.resolved")}</div>
          </div>
      </div>
    </div>
    <div className={"savings " + (savingsSaved ? "saved" : "loss")}>
      <div className="text">
        {savingsSaved
          ? <div className="title"> {t("displaySumup.saved.title")} <FontAwesomeIcon icon="thumbs-up" /></div>
          : <div className="title"> {t("displaySumup.loss.title")} <FontAwesomeIcon icon="face-sad-tear" /></div>}
        <div className="description">{savingsSaved ? t("displaySumup.saved.description") : t("displaySumup.loss.description")}</div>
      </div>
      <div className="amount">
        <div className="status">{savingsSaved ? t("displaySumup.saved.text") : t("displaySumup.loss.text")}</div>
        <div className="value">{Math.abs(resolved - total)}</div>
      </div>
    </div>
  </div>
};