import { SummaryGroup } from "../../Utilities/Api";
import DisplayAreaChart from "./Display/DisplayAreaChart";
import DisplaySumup from "./Display/DisplaySumup";
import DisplayPieChart from "./Display/DisplayPieChart";
import DisplayTable from "./Display/DisplayTable";
import DisplayHeatMatrix from "./Display/DisplayHeatMatrix";

export default function DisplayReslover(props: SummaryGroup)
{
  if(props.display === "table") return <DisplayTable {...props} />;
  if(props.display === "pieChart") return <DisplayPieChart {...props} />
  if(props.display === "areaChart") return <DisplayAreaChart {...props} />
  if(props.display === "heatMatrix") return <DisplayHeatMatrix {...props} />
  if(props.display === "sumup") return <DisplaySumup {...props} />
  return <></>;
}