import { useApi } from "../Api"

type Props = {
    id: number,
    denied: "true" | "false"
};

type Response = {}

export const useDenyOrderApi = () => {
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => 
        sendBase<Response>("set_order_denied", "POST", props);

    return {
        loading,
        send
    };
}