import { useEffect, useState } from "react"
import { useApi } from "../Api"
import { Order } from "../Types/Order"

type Response = {
    currency: string,
    items: Array<Order>
}

export const useGetOrdersApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>({ currency: "", items: [] });
    const [ error, setError ] = useState({ });

    const refresh = () =>
        sendBase<Response>("get_orders", "POST", {})
            .then(setResponse)
            .catch(setError);

    useEffect(() => {
        refresh();
    }, [])

    return {
        loading,
        response,
        error, 
        refresh
    }
}