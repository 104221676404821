
import { useState } from 'react'
import { useAuth } from '../Auth/UseAuth'
import { useGlobalError } from '../../Components/GlobalError/GlobalError'
import { API_ENDPOINT } from '../Constants'
import { useTranslation } from 'react-i18next'

type SendOpt = {
    minTime?: number
}

type MethodType = "POST" | "GET" | "PUT" | "DELETE"

export const useApi = () => {
    const { t } = useTranslation("errors");
    const [ loading, setLoading ] = useState(false);
    const { accessToken, setAccessToken } = useAuth();
    const { setError } = useGlobalError();

    const send = <ResposneType>(
        endpoint: string, 
        method: MethodType, 
        params: any, 
        opt?: SendOpt
    ) => new Promise<ResposneType>((resolve, reject) => {
        setLoading(true);

        Promise.all([
            new Promise<void>((resolve) => {
                if(opt?.minTime) setTimeout(resolve, 500)
                else resolve()
            }),
            maybeRefreshRequest<ResposneType>(
                endpoint,
                method,
                params,
                accessToken,
                setAccessToken
            )
        ])
            .then(([_, response]) => response)
            .then(resolve)
            .catch(reason => {
                if(reason.errors) 
                    reject(reason);
                else
                    setError("wifi", t("unreachable_server"));
            })
            .finally(() => {
                setLoading(false);
            });
    });

    return { 
        send,
        loading
    }
}

const maybeRefreshRequest = <ResposneType>(endpoint: string, method: MethodType, params: any, accessToken: string, setAccessToken: any): PromiseLike<ResposneType> => 
    new Promise((resolve, reject) => {
        request(endpoint, method, params, accessToken)
            .then(response => {
                if(response.errors)
                {
                    if(response.errors.includes("invalid_access_token"))
                        refreshRequest(accessToken)
                            .then(refreshResponse => {
                                setAccessToken(refreshResponse.accessToken);
                                request(endpoint, method, params, refreshResponse.accessToken)
                                    .then(response => {
                                        if(response.errors) reject(response);
                                        else resolve(response);
                                    })
                                    .catch(reject);
                            })
                            .catch(reason => {
                                console.log(reason);
                                setAccessToken("");
                                reject(reason);
                            });
                    else 
                        reject(response);
                }
                else resolve(response);
            })
            .catch(reject)
    })
    

// const request = (endpoint: string, method: MethodType, params: any, accessToken: string) =>
//     fetch(API_ENDPOINT + "/" + endpoint + ".php", {
//         method: method,
//         body: method === "GET"
//             ? undefined 
//             : JSON.stringify({ ...params, api_key: accessToken }),
//         headers: {
//             "Content-Type": "application/json"
//         }
//     })
//     .then(response => response.json());

const request = (endpoint: string, method: MethodType, params: any, accessToken: string) =>
    fetch(API_ENDPOINT + "/" + endpoint + ".php", {
        method: method,
        body: buildBody(params, accessToken)
    })
    .then(response => Promise.all([ new Promise(resolve => resolve(response)), response.json() ]))
    .then(([ response, data ]) => {
        if (!(response as Response).ok) throw data;
        return data
    });
    
const buildBody = (params: any, accessToken: string) => {
    const formData = new FormData();
    const data: any = {};
    Object.entries({ ...params, api_key: accessToken })
        .forEach(([key, value]) => {
            if (value instanceof Blob){
                console.log(key);
                formData.append(key, value);
            }
            else
                data[key] = value;
        });
    formData.append("data", JSON.stringify(data));
    
    return formData;
};  

const refreshRequest = (accessToken: string) => 
    request(
        "refresh",
        "GET",
        {},
        accessToken
    );