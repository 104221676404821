import './Page.scss';
import Nav from '../Nav/Nav';
import NavBar from '../NavBar/NavBar';
import { useState } from 'react';
import GlobalError from '../GlobalError/GlobalError';
import { useSettings } from '../../Utilities/Settings/UseSettings';
import GlobalSuccess from '../GlobalSuccess/GlobalSuccess';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string,
  title?: string,
  children: React.ReactNode
}

export default function Page({ id, title, children }: Props)
{
  const { t } = useTranslation("nav");
  const { theme } = useSettings();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={`page theme-${theme}`}>
      <div>
        <Nav open={menuOpen} onMenuHide={() => setMenuOpen(false)}/>
        {title 
          ? (
            <div className="content">
              <header>
                <h2>{t(title)}</h2>
              </header>
              {children}
            </div>)
          : 
          children}
      </div>
      <NavBar 
        activeItem={id} 
        onMenuToggle={() => setMenuOpen(!menuOpen)}
        onMenuHide={() => setMenuOpen(false)}/>
      <GlobalError />
      <GlobalSuccess />
    </div>
  );
}
