import { useState } from "react";
import { useApi } from "../Api"
import { Range } from "./CheckRanges";

type Props = {
    sizes: number[],
    key?: string
};

type Response = {
    ranges: Range[]
};

export const useFindRangesApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>();

    const send = (props: Props) => 
        sendBase<Response>("find_ranges", "POST", props)
            .then(setResponse);

    return {
        loading,
        response,
        send
    };
}