import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, LoaderIcon, Spinner } from '../../Components/UI';
import './RangeCheck.scss';
import { useTranslation } from 'react-i18next';
import { FormEvent, useState } from 'react';
import { useCheckRangesApi, Range } from '../../Utilities/Api';
import { useSettings } from '../../Utilities/Settings/UseSettings';
import { useParams } from 'react-router-dom';
import { useGlobalError } from '../../Components/GlobalError/GlobalError';

const newItem: Range = {
  first: "00000000",
  last: "00001000"
};

const initalItems = [newItem];

type Check = {
  correct_format: { range: Range }[],
  database_containing: { range: Range }[],
  new_overlap: { range: Range }[]
};

const rangeHasError = (range: Range, check: Check): boolean => {
  let output = false;

  Object.values(check).forEach((value) => {
    if (value.find(v => v.range.first === range.first && v.range.last === range.last))
      output = true;
  })

  return output;
}

export default function RangeCheck() {
  const { t } = useTranslation("rangeCheck");
  const { setError } = useGlobalError();
  const [items, setItems] = useState(initalItems);
  const { send, response, loading } = useCheckRangesApi();
  const { theme } = useSettings();
  const { key } = useParams();
  
  const handleNewClick = () => {
    setItems([...items, newItem]);
  };

  const updateValue = (id: number, boundary: "first" | "last", value: string) => {
    setItems(prev => {
      return prev.map((person, i) => {
        if (i === id)
          return { ...person, [boundary]: value };
        return person;
      });
    });
  };

  const deleteOne = (id: number) => {
    setItems(items.filter((item, key) => key !== id));
  };

  const submit = () => {
    send({
      ranges: items,
      key
    })
      .catch(reason => {
        setError("user", "Invalid URL key!");
      });
  };

  return (
    <div className="content">
      <header>
        <h2>{t('title')}</h2>
        <Button 
          size="small"
          icon={<FontAwesomeIcon icon="plus"/>} 
          onClick={handleNewClick} />
      </header>
      <div className="rangeCheck">
        <div className="container">
          <header>
            <div>
              {t("range.first")}
            </div>
            <div>
              {t("range.last")}
            </div>
          </header>
          {items.map((item, key) => (
            <div className="item" key={key} data-status={rangeHasError(item, response) ? "error" : ""}>
              <label>{t("range.first")}</label>
              <Input value={item.first} onInput={(e: FormEvent<HTMLInputElement>) => updateValue(key, "first", (e.target as HTMLInputElement).value)} />
              <div>-</div>
              <label>{t("range.last")}</label>
              <Input value={item.last} onInput={(e: FormEvent<HTMLInputElement>) => updateValue(key, "last", (e.target as HTMLInputElement).value)} />
              {items.length > 1 && <Button 
                size="large"
                mode="link"
                type="button"
                color="danger"
                onClick={() => deleteOne(key)}
                icon={<FontAwesomeIcon icon="times"/>} />}
            </div>
          ))}
        </div>
        <div className="container">
          <div className='output'>
            {Object.entries(response).map(([key, value]) => {
              // if (!["correct_format", "database_containing", "new_containing"].includes(key)) return <></>;
              const status = value.length === 0 ? "ok" : "error";
              return (
                <div className='item' key={key} data-status={loading ? "" : status}>
                  <div className='label'>{t(`output.${key}`)}</div>
                  <div className='status'>
                    {loading
                      ? <Spinner><LoaderIcon color={theme === "light" ? "black" : "white"} /></Spinner>
                      : <FontAwesomeIcon icon={status === "ok" ? "check" : "times"} />}
                  </div>
                </div>
              );
            })}
          </div>
          <Button 
            size="large" 
            type="button"
            onClick={() => submit()}
            icon={loading ? <Spinner><LoaderIcon color='white'/></Spinner> : <FontAwesomeIcon icon="arrow-right"/>}>
            {t("submit")}
          </Button>
        </div>
      </div>
    </div>
  );
}
