import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocationSuggestion } from "../../../Utilities/Api/Types/LocationSuggestion";
import { Button } from "../../../Components/UI";
import { useAuth } from "../../../Utilities/Auth/UseAuth";
import { API_ENDPOINT } from "../../../Utilities/Constants";

type Props = {
  onClick: () => void,
  onDelete: () => void
} & LocationSuggestion

export default function Item({ onClick, onDelete, ...props }: Props)
{
  const { accessToken } = useAuth();
  const img = `${API_ENDPOINT}/get_upload.php?api_key=${accessToken}&id=${props.id}&location=location_suggestions`;

  return (
    <div className="item">
      <header>
        <img src={img} alt="" onClick={() => { window.open(img, "_blank"); }}></img>
        <span className="name">{props.name}</span>
        <span className="address">{props.address}</span>
        <span className="mail"><FontAwesomeIcon icon="at" /> {props.mail}</span>
      </header>
      <div className="buttons">
        <Button 
          size="small" 
          type="button"
          color="danger"
          mode="secondary"
          onClick={onDelete}
          icon={<FontAwesomeIcon icon="trash" />}>
            Smazat
        </Button>
        <Button 
          size="small" 
          type="button"
          color="primary"
          mode="primary"
          onClick={onClick}
          icon={<FontAwesomeIcon icon="location-arrow" />}>
            Přidat
        </Button>
      </div>
    </div>
  );
}