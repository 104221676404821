import { Rights, useAuth } from "../../Auth/UseAuth"
import { useApi } from "../Api"

type Props = {
    mail: string,
    password: string
}

type Response = {
    accessToken: string,
    rights: Rights
}

export const useLoginApi = () => {
    const { setAccessToken, setRights } = useAuth();
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => new Promise<Response>((resolve, reject) => {
        sendBase<Response>("login", "POST", props, { minTime: 500 })
            .then(response => {
                setAccessToken(response.accessToken);
                setRights(response.rights);
                resolve(response);
            })
            .catch(reject);
    });

    return {
        loading,
        send
    };
}