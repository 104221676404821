import { useEffect, useState } from "react"
import { useApi } from "../Api"
import { Summary } from "../Types/Summary"

type Response = Summary

export const useGetSummaryApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>();
    const [ error, setError ] = useState({ });

    const refresh = () =>
        sendBase<Response>("summary", "POST", {})
            .then(setResponse)
            .catch(setError);

    useEffect(() => {
        refresh();
    }, [])

    return {
        loading,
        response,
        error, 
        refresh
    }
}