import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Nav.scss';
import NavItem from "./NavItem/NavItem"
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import character from '../../Images/character.svg';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../Utilities/Auth/UseAuth';

type Props = {
    open: boolean,
    onMenuHide: () => void
}

export default function Nav({ open, onMenuHide }: Props)
{
    const { t } = useTranslation("nav");
    const navigate = useNavigate();
    const { hasRights } = useAuth();

    const handleItemClick = (route: string) => {
        navigate(route);
        onMenuHide();
    };

    return (
        <nav className={open ? "open" : ""}>
            <div className="container">
                <header>
                    <div className="container">
                        <h2>{t("title")}</h2>
                        <img src={character} alt="" className="character" onClick={() => handleItemClick("/settings/view")} />
                    </div>
                </header>
                <div className="items top">
                    <NavItem icon={<FontAwesomeIcon icon="chart-line" />} text={t("summary")} onClick={() => handleItemClick("/summary")} />
                    {hasRights("admin") && <NavItem icon={<FontAwesomeIcon icon="right-left" />} text={t("orders")} onClick={() => handleItemClick("/orders")} />}
                    {hasRights("admin") && <NavItem icon={<FontAwesomeIcon icon="location-dot" />} text={t("locations")} onClick={() => handleItemClick("/locations")} />}
                    {hasRights("admin") && <NavItem icon={<FontAwesomeIcon icon="magnifying-glass-location" />} text={t("location-suggestions")} onClick={() => handleItemClick("/locations/suggestions")} />}
                    {hasRights("admin") && <NavItem icon={<FontAwesomeIcon icon="store" />} text={t("stores")} onClick={() => handleItemClick("/stores")} />}
                    {/* {hasRights("admin") && <NavItem icon={<FontAwesomeIcon icon="barcode" />} text={t("scans")} onClick={() => handleItemClick("/scans")} />} */}
                    {/* <NavItem icon={<FontAwesomeIcon icon="clipboard-check" />} text={t("range-check")} onClick={() => handleItemClick("/range-check")} /> */}
                    {/* <NavItem icon={<FontAwesomeIcon icon="file-circle-plus" />} text={t("range-generate")} onClick={() => handleItemClick("/range-generate")} /> */}
                    <NavItem icon={<FontAwesomeIcon icon="cog" />} text={t("settings")} onClick={() => handleItemClick("/settings/view")} />
                </div>
                <div className="items bottom">
                    <NavItem 
                        icon={<FontAwesomeIcon icon="sign-out-alt" />} 
                        text={t("logout")}
                        onClick={() => handleItemClick("/logout")}  />
                </div>
            </div>
        </nav>
    );
}
