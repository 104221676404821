import { SummaryGroupHeatMatrix, bt } from "../../../Utilities/Api";
import "./DisplayHeatMatrix.scss";
import { useTranslation } from "react-i18next";

type Props = SummaryGroupHeatMatrix;

export default function DisplayHeatMatrix({ data }: Props)
{
  const { t } = useTranslation("summary");

  const max = data.groups.reduce((acc, cur) => Math.max(acc, cur.values[0]), 1);
  const sum = data.groups.reduce((acc, cur) => acc + cur.values[0], 0);
  return <div className="displayHeatMatrix">
    <h3>Celkem: {sum}</h3>
    <div className="graph">
      {data.groups.map((item, key) => 
        <div className="cell" key={key} style={{ background: `rgba(0, 100, 232, ${item.values[0]/max * 0.95 + 0.05})` }}>
          <div className="popup">
            <h2>{item.values[0]}</h2>
            <p>{bt(t, item.label)}</p>
          </div>
        </div>
      )}
    </div>
  </div>
};