import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../Components/UI";
import { Order } from "../../../Utilities/Api";
import React from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import img0 from "../../../Images/Prizes/0.png";
// @ts-ignore
import img1 from "../../../Images/Prizes/1.png";
// @ts-ignore
import img2 from "../../../Images/Prizes/2.png";
// @ts-ignore
import img3 from "../../../Images/Prizes/3.png";
// @ts-ignore
import img4 from "../../../Images/Prizes/4.png";
// @ts-ignore
import img5 from "../../../Images/Prizes/5.png";
// @ts-ignore
import img6 from "../../../Images/Prizes/6.png";
// @ts-ignore
import img7 from "../../../Images/Prizes/7.png";

const imgs = [img0, img1, img2, img3, img4, img5, img6, img7];

type Props = {
  expanded: boolean,
  onClick: () => void,
  onEditClick: () => void
} & Order

export default function Item({ expanded, onClick, onEditClick, ...props }: Props)
{
  const { t } = useTranslation("orders");
  const flagExpanded = expanded ? "expanded" : ""
  const className = `item ${flagExpanded}`;

  return (
    <div className={className}>
      <header onClick={onClick}>
        <div className="icon">
          <img src={imgs[props.prize]} alt={props.prize.toString()} />
        </div>
        <h2>{props.message.primary}</h2>
        <div className={`status${props.status ? "" : (props.denied ? " denied" : " unresolved")}`}>
          {t(props.status || (props.denied ? "denied" : "unresolved"))}
        </div>
        <div className="expandedIcon">
          <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} />
        </div>
      </header>
      <div className="detail">
        <div className="info">
          <InfoGroup label={t("mail")} value={props.mail} />
          <InfoGroup label={t("phone")} value={props.phone} />
          <InfoGroup label={t("first_name")} value={props.first_name} />
          <InfoGroup label={t("last_name")} value={props.last_name} />
          <InfoGroup label={t("street")} value={props.street} />
          <InfoGroup label={t("city")} value={props.city} />
          <InfoGroup label={t("city_code")} value={props.city_code} />
          <InfoGroup label={t("country")} value={props.country} />
          <InfoGroup label={t("code")} value={props.code} />
        </div>
        <Button 
          onClick={onEditClick}
          icon={<FontAwesomeIcon icon="pen" />}
          color="primary"
          mode="secondary">
          {t("detail")}
        </Button>
      </div>
    </div>
  );
}

type InfoGroupProps = {
  label: string, 
  value?: string, 
  unit?: string,
  children?: React.ReactNode
}

const InfoGroup = ({ label, value, unit = "", children }: InfoGroupProps) => {
  return (
    <div className="infoGroup">
      <div className="label">{label}</div>
      <div className="value">
        {children}
        <div className="value">{value}</div>
        <div className="unit">{unit}</div>
      </div>
    </div>
  );
}