import { useApi } from "../Api"
import { LocationSuggestion } from "../Types/LocationSuggestion";

type Props = Pick<LocationSuggestion, "id">;

type Response = {}

export const useDeleteLocationSuggestionApi = () => {
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => 
        sendBase<Response>("delete_location_suggestion", "POST", props);

    return {
        loading,
        send
    };
}