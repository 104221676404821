import './NavBar.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../Utilities/Auth/UseAuth';

type Props = {
    activeItem: string,
    onMenuToggle: () => void,
    onMenuHide: () => void
}

export default function NavBar({ activeItem, onMenuToggle, onMenuHide }: Props)
{
    const { t } = useTranslation("nav");
    const { hasRights } = useAuth();
    
    let items = [
        {
            id: "summary",
            page: "/summary",
            icon: <FontAwesomeIcon icon="chart-line" />,
            text: t("summary")
        }
    ];

    if (hasRights("admin"))
        items = [
            ...items, 
            {
                id: "orders",
                page: "/orders",
                icon: <FontAwesomeIcon icon="right-left" />,
                text: t("orders")
            },
            {
                id: "locations",
                page: "/locations",
                icon: <FontAwesomeIcon icon="location-dot" />,
                text: t("locations")
            },
            {
                id: "location-suggestions",
                page: "/locations/suggestions",
                icon: <FontAwesomeIcon icon="magnifying-glass-location" />,
                text: t("location-suggestions")
            }
        ];

    if (hasRights("user"))
        items = [
            ...items,
            {
                id: "range-check",
                page: "/range-check",
                icon: <FontAwesomeIcon icon="clipboard-check" />,
                text: t("range-check")
            },
            {
                id: "range-generate",
                page: "/range-generate",
                icon: <FontAwesomeIcon icon="file-circle-plus" />,
                text: t("range-generate")
            }
        ];

    const navigate = useNavigate();

    const handleItemClick = (index: number) => {
        navigate(items[index].page);
        onMenuHide();
    }

    return (
        <div className='navbar'>
            {items.map(({id, icon, text}, index) => (
                <div 
                    key={index}
                    onClick={() => handleItemClick(index)}
                    className={activeItem === id ? "active" : ""}>
                    {icon}
                    <div>{text}</div>
                </div>
            ))}
            <div 
                onClick={onMenuToggle}  
                className={!(items.map(i => i.id).includes(activeItem)) ? "active" : ""}>
                <FontAwesomeIcon icon="bars" />
                <div>More</div> 
            </div>
        </div>
    );
}
