import { useEffect, useState } from "react"
import { useApi } from "../Api"
import { Location } from "../Types/Location"
import { Store } from "../Types/Store"

type Response = {
    stores:  Array<Store>,
    locations: Array<Location>
}

export const useGetShopsApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>({ stores: [], locations: [] });
    const [ error, setError ] = useState({ });

    const refresh = () =>
        sendBase<Response>("get_shops", "POST", {})
            .then(setResponse)
            .catch(setError);

    useEffect(() => {
        refresh();
    }, [])

    return {
        loading,
        response,
        error, 
        refresh
    }
}