import { useApi } from "../Api"
import { Store } from "../Types/Store";

type Props = Pick<Store, "id">;

type Response = {}

export const useDeleteStoreApi = () => {
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => 
        sendBase<Response>("delete_store", "POST", props);

    return {
        loading,
        send
    };
}