import { create } from "zustand"
import { persist } from 'zustand/middleware'

export type Rights = "admin" | "user";

export type AuthType = {
    accessToken: string,
    rights: Rights,
    setAccessToken: (accessToken: string) => void,
    setRights: (rights: Rights) => void,
    loggedIn: () => boolean,
    hasRights: (rights: Rights | Rights[]) => boolean;
}

export const useAuth = create<AuthType>()(persist(
    (set, get) => ({
        accessToken: "",
        rights: "user",
        setAccessToken: (accessToken: string) => set(() => ({ accessToken })),
        setRights: (rights: Rights) => set(() => ({ rights })),
        loggedIn: () => get().accessToken.length > 0,
        hasRights: (rights: Rights | Rights[]) => {
            const r = get().rights;
            if (Array.isArray(rights)) return rights.includes(r);
            else return r === rights;
        }
    }),
    {
        name: "authStore"
    }
));