import { useState } from 'react';
import { Order, useGetOrdersApi } from '../../Utilities/Api';
import Item from './Item/Item'

import './Orders.scss';
import { EmptyList } from '../../Components/UI';
import { Form, Modal } from './Modal/Modal';
import { CurrencyContext } from '../../Utilities/Context/CurrencyContext';
import { useTranslation } from 'react-i18next';

export default function Orders()
{
  const { t } = useTranslation("orders");
  const { response, refresh } = useGetOrdersApi();
  const [expanded, setExpanded] = useState(-1);
  const [modal, setModal] = useState<Form>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);


  const handleClose = (shouldRefresh: boolean) => {
    setModalVisible(false);
    if(shouldRefresh) {
      setExpanded(-1);
      refresh();
    }
  };

  const handleEditClick = (item: Order) => {
    setModalVisible(true);
    setModal({
      id: item.id,
      code: item.code,
      created_date: item.created_date,
      mail: item.mail,
      phone: item.phone,
      first_name: item.first_name,
      last_name: item.last_name,
      street: item.street,
      city: item.city,
      city_code: item.city_code,
      country: item.country,
      note: item.note,
      status:	item.status,
      prize: item.prize,
      denied: item.denied
    });
  };

  return (
    <div className="content">
      <CurrencyContext.Provider value={response.currency}>
        <header>
          <h2>{t('title')}</h2>
        </header>
        <div className="orders">
          <div className="container">
          {response.items.length === 0
              ? <EmptyList text={t('empty.text')}/>
              : response.items.map((item, index) => 
                <Item 
                  key={index} 
                  expanded={index === expanded}  
                  onClick={() => setExpanded(index === expanded ? -1 : index)}
                  onEditClick={() => handleEditClick(item)}
                  {...item} />
              )}
          </div>
        </div>
        <Modal visible={modalVisible} onClose={handleClose} values={modal} />
      </CurrencyContext.Provider>
    </div>
  );
}
