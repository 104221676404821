import { IconProp } from "@fortawesome/fontawesome-svg-core";

export type Note = {
    type: "info" | "warning" | "error",
    message: BackendText
};

export type BackendText = {
    t: true,
    key: string
} | string;

export type Summary = {
    currency: string,
    tiles: Array<SummaryTile>
};

export type SummaryTile = {
    title?: string,
    subtitle?: string,
    groups: Array<SummaryGroup>,
    note?: Note
};

export type SummaryGroup = 
    | SummaryGroupTable
    | SummaryGroupPieChart
    | SummaryGroupAreaChart
    | SummaryGroupSumup
    | SummaryGroupHeatMatrix

type SummaryGroupCommon = {
    title?: BackendText,
    valueSuffix?: BackendText
}

export type SummaryGroupTable = SummaryGroupCommon & {
    display: "table",
    meta: {
        columns: Array<{
            label: string
        }>
    },
    data: SummaryGroupData
}

export type SummaryGroupPieChart = SummaryGroupCommon & {
    display: "pieChart",
    data: SummaryGroupData
}

export type SummaryGroupAreaChart = SummaryGroupCommon & {
    display: "areaChart",
    data: SummaryGroupData
}

export type SummaryGroupHeatMatrix = SummaryGroupCommon & {
  display: "heatMatrix",
  data: SummaryGroupData
}

export type SummaryGroupSumup = SummaryGroupCommon & {
    display: "sumup",
    data: SummaryGroupData
}

export type SummaryGroupData = {
    groups: Array<DataGroup>
};

type DataGroup = {
    label: BackendText,
    color?: "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9",
    icon?: IconProp,
    values: Array<number>
};

export const bt = (t: (a: string) => string, bt: BackendText): string => {
    if (typeof bt === 'string') return bt;
    return t(bt.key);
};