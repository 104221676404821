import { create } from "zustand"
import { persist } from 'zustand/middleware'
import i18n from "../Locale/i18n"

const DEFAULT_THEME = "light";

export type LanguageType = "cs" | "en"

export type ThemeType = "light" | "dark"

export type ColorPalette<T> = {[key in ThemeType]: T}

export type SettingsType = {
    getLanguage: () => LanguageType,
    theme: ThemeType,
    setLanguage: (language: LanguageType) => void,
    setTheme: (theme: ThemeType) => void,
    selectPalette: <T>(colorPalette: ColorPalette<T>) => T
}

export const useSettings = create<SettingsType>()(persist(
    (set, get) => ({
        getLanguage: () => (i18n.resolvedLanguage as LanguageType),
        theme: DEFAULT_THEME,
        setLanguage: (language: LanguageType) => {
            i18n.changeLanguage(language);
        },
        setTheme: (theme: ThemeType) => set(() => ({ theme })),
        selectPalette: <T>(colorPalette: ColorPalette<T>): T => colorPalette[get().theme]
    }),
    {
        name: "settingsStore"
    }
));