import { useApi } from "../Api"
import { Location } from "../Types/Location";

type Props = Location;

type Response = {}

export const useEditLocationApi = () => {
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => 
        sendBase<Response>("edit_location", "POST", props);

    return {
        loading,
        send
    };
}