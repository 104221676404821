import { SummaryGroup } from "../../Utilities/Api";
import DisplayReslover from "./DisplayResolver";


export default function Group(props: SummaryGroup)
{
  return (
    <div className='group'>
      <DisplayReslover {...props} />
    </div>
  );
}