import { useNavigate } from 'react-router-dom';
import { Button } from '../../Components/UI';
import './Settings.scss';

import About from "./Pages/About"
import View from "./Pages/View"
import { useTranslation } from 'react-i18next';

type Page = "board" | "user" | "view" | "about";

type Props = {
  page: Page
}

export default function Settings({ page }: Props)
{
  const navigate = useNavigate();
  const { t } = useTranslation("settings");

  return (
    <div className="settings">
        <div className="nav">
          <Button mode={page === "view" ? "secondary" : "link"} onClick={_=>navigate("/settings/view")}>
            {t("view.title")}
          </Button>
          <Button mode={page === "about" ? "secondary" : "link"} onClick={_=>navigate("/settings/about")}>
            {t("about.title")}
          </Button>
        </div>
        <div className="subpage">
          {page === "view" && <View />}
          {page === "about" && <About />}
        </div>
    </div>
  );
}
