import { useApi } from "../Api"
import { Location } from "../Types/Location";

type Props = Pick<Location, "id">;

type Response = {}

export const useDeleteLocationApi = () => {
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => 
        sendBase<Response>("delete_location", "POST", props);

    return {
        loading,
        send
    };
}