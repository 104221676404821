import { useState } from 'react';
import { Store, useGetShopsApi } from '../../Utilities/Api';
import Item from './Item/Item'

import './Stores.scss';
import { Button, EmptyList } from '../../Components/UI';
import { Form, Modal } from './Modal/Modal';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Stores()
{
  const { t } = useTranslation("stores");
  const { response, refresh } = useGetShopsApi();
  const [modal, setModal] = useState<Form>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleNewClick = () => {
    setModalVisible(true);
    setModal(undefined);
  };

  const handleClose = (shouldRefresh: boolean) => {
    setModalVisible(false);
    if(shouldRefresh)
      refresh();
  };

  const handleClick = (item: Store) => {
    setModalVisible(true);
    setModal(item);
  };

  return (
    <div className="content">
      <header>
        <h2>{t('title')}</h2>
        <Button 
          size="small"
          icon={<FontAwesomeIcon icon="plus"/>} 
          onClick={handleNewClick} />
      </header>
      <div className="stores">
        <div className="container">
          {response.stores.length === 0
            ? <EmptyList text={t('empty.text')}/>
            : response.stores.map((item, index) => 
              <Item 
                key={index}
                count={response.locations.filter(l => l.store === item.id).length}
                onClick={() => handleClick(item)}
                {...item} />
            )}
        </div>
      </div>
      <Modal visible={modalVisible} onClose={handleClose} values={modal} />
    </div>
  );
}
