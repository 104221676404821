import React, { FormEvent, useRef, useState } from 'react';
import './FileInput.scss';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';

export type FileInputProps = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> & {
  onChange: (f: File) => void
}

export const FileInput = ({ onChange, value, ...props }: FileInputProps) => {
  const [name, setName] = useState<string>();
  const input = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("ui");

  const buttonClicked = () => {
    input.current?.click();
  };

  return (
    <label className='fileInput'>
      <input 
        type="file"
        accept="image/png, image/jpeg"
        value={value}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          const files = (e.target as HTMLInputElement).files;
          if (files) {
            setName(files[0].name);
            onChange(files[0]);
          }
        }}
        ref={input}
        {...props}/>
      <Button size='large' color='primary' mode='secondary' type='button' onClick={buttonClicked}>
        {t("fileInput.selectFile")}
      </Button>
      <div className='name'>{name || t("fileInput.fileNotSelected")}</div>
    </label>
  );
}