import { useTranslation } from 'react-i18next';
import { SummaryTile, bt } from '../../Utilities/Api';
import Group from './Group';
import './Summary.scss';
import { Select } from '../../Components/UI';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Tile({ tile }: { tile: SummaryTile})
{
  const { t } = useTranslation("summary");
  const [activeGroup, setActiveGroup] = useState(0);

  return (
    <div className='tile'>
      {tile.title && <h3>{t(tile.title)}</h3>}
      {tile.subtitle && <div className='subtitle'>{t(tile.subtitle)}</div>}
      <Group {...tile.groups[activeGroup]} />
      {tile.groups.length > 1 && <Select 
        value={activeGroup.toString()}
        onChange={i => setActiveGroup(Number(i))}
        options={tile.groups.map((g, i) => ({
          value: i.toString(), 
          content: <div>{bt(t, g.title || i.toString())}</div>
        }))}/>}
      {tile.note && <div className='note' data-type={tile.note.type}>
        <FontAwesomeIcon icon={tile.note.type === "info" ? "circle-exclamation" : "triangle-exclamation"} />
        {bt(t, tile.note.message)}
      </div>}
    </div>
  );
}
