import { useApi } from "../Api"

type Props = {
    id: number,
    status: string
};

type Response = {}

export const useEditOrderApi = () => {
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => 
        sendBase<Response>("set_order_status", "POST", props);

    return {
        loading,
        send
    };
}