import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalError } from "../../../Components/GlobalError/GlobalError";
import { Modal as BaseModal } from "../../../Components/Modal/Modal";
import { Button, Input, LoaderIcon, Select, Spinner } from "../../../Components/UI";
import { Errors, FormError, Location, Store, useEditLocationApi, useDeleteLocationApi, useNewLocationApi } from "../../../Utilities/Api";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Confirm } from "../../../Components/Confirm/Confirm";

type Props = {
  visible: boolean,
  stores: Array<Store>,
  values: Form | undefined,
  forceNew?: true,
  onClose: (refresh: boolean) => void
}

type Mode = "new" | "edit"

export type Form = Location;

export const defaultValues = {
  id: 0,
  lat: 0,
  lng: 0,
  store: 1,
  address: ""
};

export const Modal = ({ forceNew, visible, stores, onClose, values }: Props) =>
{
  const { t } = useTranslation("locations");
  const { setError } = useGlobalError();
  const {send: sendNew, loading: loadingNew} = useNewLocationApi();
  const {send: sendEdit, loading: loadingEdit} = useEditLocationApi();
  const {send: sendDelete, loading: loadingDelete} = useDeleteLocationApi();
  const [deleting, setDeleting] = useState(false);
  const [mode, setMode] = useState<Mode>("new");
  const [form, setForm] = useState<Form>(defaultValues);
  const [errors, setErrors] = useState<Errors>([]);

  const updateForm = (newValues: Partial<Form>) => {
    setForm(v => ({ ...v, ...newValues }));
  };

  useEffect(() => {
    if(!visible) return;
    const newValues = values || defaultValues;
    updateForm(newValues);
    setMode(values === undefined || forceNew ? "new" : "edit");
    setDeleting(false);
    setErrors([]);
  }, [forceNew, visible, values]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if(mode === "new") {
      sendNew(form)
        .then(() => onClose(true))
        .catch(reason => {
          if(reason.errors)
            setErrors(reason.errors);
        });
    }
    else
      sendEdit(form)
        .then(() => onClose(true))
        .catch(reason => {
          if(reason.errors)
          {
            if(reason.errors.includes("location_not_found"))
              setError("warning", t("notFound"));
            setErrors(reason.errors);
          }
        });
  };

  const handleDeleteClick = () => {
    setDeleting(true);
  };

  const handleDeleteProceed = () => {
    sendDelete({ id: form.id })
      .then(() => onClose(true))
      .catch(reason => {
        if(reason.errors && reason.errors.includes("location_not_found"))
          setError("warning", t("notFound"));
      });
  };

  return (
    <BaseModal 
      visible={visible} 
      title={deleting 
        ? t("modal.delete.title")
        : (mode === "new" 
          ? t("modal.new.title")
          : t("modal.edit.title"))} 
      onClose={() => onClose(false)}>
        {deleting 
          ? <Confirm 
            text={t("modal.delete.confirm")}
            proceed={{ text: t("modal.delete.submit"), icon: "skull" }}
            abort={{ text: t("modal.delete.abort") }}
            onProceed={handleDeleteProceed}
            onAbort={() => setDeleting(false)} />
          : <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="column">
                  {(["lat", "lng", "address"] as (keyof Form)[]).map(key => 
                    <div className="formGroup" key={key}>
                      <label>{t(`modal.form.${key}.label`)}</label>
                      <Input 
                        placeholder={t(`modal.form.${key}.placeholder`) || ""}
                        value={form[key]}
                        onInput={(e: FormEvent<HTMLInputElement>) => updateForm({ [key]: (e.target as HTMLInputElement).value })}/>
                      <FormError field={key} errors={errors} />
                    </div>
                  )}
                  <div className="formGroup half">
                    <label>{t("modal.form.store.label")}</label>
                    <Select 
                      value={form.store.toString()}
                      onChange={store => updateForm({ store: Number(store) })}
                      options={stores.map(s => ({
                        value: s.id.toString(), 
                        content: <div>{s.name}</div>
                      }))}/>
                  </div>
                </div>
              </div>
              {mode === "new" 
                ? <Button 
                    size="large" 
                    type="submit" 
                    icon={loadingNew && <Spinner><LoaderIcon color='white'/></Spinner>}>
                    {t("modal.new.submit")}
                  </Button>
                : <div className="formRow">
                    <div className="formGroup half" style={{width: "44px", flex: 0}}>
                      <Button
                        size="large"
                        mode="secondary"
                        type="button"
                        onClick={handleDeleteClick}
                        icon={loadingDelete
                          ? <Spinner><LoaderIcon color='white'/></Spinner> 
                          : <FontAwesomeIcon icon="trash" />}>
                      </Button>
                    </div>
                    <div className="formGroup half"> 
                      <Button 
                          size="large" 
                          type="submit"
                          icon={loadingEdit && <Spinner><LoaderIcon color='white'/></Spinner>}>
                          {t("modal.edit.submit")}
                        </Button>
                      </div>
                  </div>
              }
            </form>
      }
    </BaseModal>
  );
}