import { useState } from 'react';
import { Location, useGetShopsApi } from '../../Utilities/Api';
import Item from './Item/Item'

import './Locations.scss';
import { Button, EmptyList } from '../../Components/UI';
import { Form, Modal } from './Modal/Modal';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Locations()
{
  const { t } = useTranslation("locations");
  const { response, refresh } = useGetShopsApi();
  const [modal, setModal] = useState<Form>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleNewClick = () => {
    setModalVisible(true);
    setModal(undefined);
  };

  const handleClose = (shouldRefresh: boolean) => {
    setModalVisible(false);
    if(shouldRefresh)
      refresh();
  };

  const handleClick = (item: Location) => {
    setModalVisible(true);
    setModal(item);
  };

  return (
    <div className="content">
      <header>
        <h2>{t('title')}</h2>
        <Button 
          size="small"
          icon={<FontAwesomeIcon icon="plus"/>} 
          onClick={handleNewClick} />
      </header>
      <div className="locations">
        <div className="container">
        {response.locations.length === 0
            ? <EmptyList text={t('empty.text')}/>
            : response.locations.map((item, index) => 
              <Item 
                key={index}
                stores={response.stores}
                onClick={() => handleClick(item)}
                {...item} />
            )}
        </div>
      </div>
      <Modal visible={modalVisible} stores={response.stores} onClose={handleClose} values={modal} />
    </div>
  );
}
