import { useEffect, useState } from 'react';
import { useApi } from '../../Utilities/Api/Api';
import './RemoteList.scss';
import { Button } from '../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Response = any;

type Props = {
  endpoint: string,
  columns: string[],
  params: object,
  itemsMapper?: (r: Response) => any[]
}

export const simpleItemsMapper = (key: string) => {
  return (response: any) => {
    if (response && response[key] && Array.isArray(response[key])) return response[key];
    return [];
  }
}

const defaultItemsMapper = simpleItemsMapper("items");

export const RemoteList = ({ endpoint, columns, params, itemsMapper=defaultItemsMapper }: Props) => {
  const [ page, setPage ] = useState(0);
  const { loading, send: sendBase } = useApi();
  const [ response, setResponse ] = useState<Response>();
  const [ error, setError ] = useState({ });

  const refresh = (n: number) =>
    sendBase<Response>(endpoint, "POST", { ...params, page: n })
        .then(setResponse)
        .catch(e => {
          console.log(e);
          setError(e);
        });

  useEffect(() => {
    refresh(page);
  }, []);

  const setPageNumber = (n: number) => {
    setPage(n);
    refresh(n);
  };

  const items = itemsMapper(response);

  return (
    <div className="remoteList">
      <div className='items'>
        <div className='table'>
          <div className='header'>
            {columns.map(c => (<div className='column' key={c}>{c}</div>))}
          </div>
          {items.map((item, i) => (<div className='item' key={i}>
            {columns.map(c => (<div className='column' key={c}>{item[c]}</div>))}
          </div>))}
        </div>
      </div>
      <div className='nav'>
        <Button
          icon={<FontAwesomeIcon icon="chevron-left" />}
          mode='secondary'
          onClick={() => setPageNumber(Math.max(0, page-1))}></Button>
        <div className='pageNumber'>page {page+1}</div>
        <Button 
          icon={<FontAwesomeIcon icon="chevron-right" />} 
          mode='secondary'
          onClick={() => setPageNumber(page+1)}></Button>
      </div>
    </div>
  );
}
