import { useState } from "react";
import { useApi } from "../Api"

export type Range = {
    first: string,
    last: string
};

type Props = {
    ranges: Range[],
    key?: string
};

type Error = {
    range: Range
};

type Response = {
    correct_format: Error[],
    database_containing: Error[],
    new_overlap: Error[]
};

const defaultResponse = {
    correct_format: [],
    database_containing: [],
    new_overlap: []
};

export const useCheckRangesApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>(defaultResponse);

    const send = (props: Props) => 
        sendBase<Response>("check_ranges", "POST", props)
            .then(setResponse);

    return {
        loading,
        response,
        send
    };
}