import { Location, Store } from "../../../Utilities/Api";

type Props = {
  stores: Array<Store>,
  onClick: () => void
} & Location

export default function Item({ stores, onClick, ...props }: Props)
{
  const store = stores.find(s => s.id === props.store);

  return (
    <div className="item">
      <header onClick={onClick}>
        <div className="icon">
          <img src={store?.logo} alt={""} />
        </div>
        <h2>{props.address}</h2>
      </header>
    </div>
  );
}