import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

export type Errors = Array<Error>

export type Error = FormError | MessageError

export type FormError = {
    field: string,
    message: string
}

export type MessageError = string

type FormErrorProps = {
    field: string,
    errors: Errors
}

const isFormError = (error: Error): error is FormError => {
    return (error as FormError).field !== undefined;
};

type MessageTranslation = {
    icon: IconProp,
    text: string
};

const messages: Record<string, Partial<MessageTranslation>> = {
    // generic
    "empty": { icon: "text-width" },
    "invalid-number": { icon: "hashtag" },
    "file-not-found": { icon: "file-circle-xmark" },
    "file-too-large": { icon: "file" },
    "not-supported-file-format": { icon: "file-circle-question" }
}

const translateMessage = (message: string, t: (s: string) => string): MessageTranslation => {
    if(messages[message] !== undefined)
    {
        const msg = messages[message];
        return {
            icon: msg.icon !== undefined ? msg.icon : "triangle-exclamation",
            text: t("form." + message)
        };
    }

    return {
        icon: "triangle-exclamation",
        text: "Error: " + message
    };
};

export const FormError = ({ field, errors }: FormErrorProps) => {
    
    const { t } = useTranslation("errors");
    const item = errors.find(i => isFormError(i) && i.field === field);
    if(!item || !isFormError(item)) return <></>;

    const { icon, text } = translateMessage(item.message, t);

    return <div className="formError">
        <FontAwesomeIcon icon={icon} />
        <div className="message">{text}</div>
    </div>;
};