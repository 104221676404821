import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, LoaderIcon, Spinner } from '../../Components/UI';
import './RangeGenerate.scss';
import { useTranslation } from 'react-i18next';
import { FormEvent, useState } from 'react';
import { useFindRangesApi } from '../../Utilities/Api';
import { useParams } from 'react-router-dom';
import { useGlobalError } from '../../Components/GlobalError/GlobalError';

const initalItem: string = "100000";

export default function RangeGenerate() {
  const { t } = useTranslation("rangeGenerate");
  const { setError } = useGlobalError();
  const [items, setItems] = useState<string[]>([initalItem]);
  const { send, response: baseResponse, loading } = useFindRangesApi();
  const { key } = useParams();
  const [ edited, setEdited ] = useState(true);
  const response = edited ? undefined : baseResponse;
  
  const handleNewClick = () => {
    setEdited(true);
    setItems([...items, initalItem]);
  };

  const updateValue = (id: number, value: string) => {
    setEdited(true);
    setItems(prev => {
      return prev.map((item, i) => {
        if (i === id)
          return value;
        return item;
      });
    });
  };

  const deleteOne = (id: number) => {
    setItems(items.filter((item, key) => key !== id));
  };

  const submit = () => {
    const sizes = items.map(n => parseInt(n));
    for (const s of sizes) {
      if (isNaN(s)) {
        setError("arrows-left-right-to-line", "Nesprávný počet!");
        return;
      }
    }

    send({
      sizes,
      key
    })
      .then(() => {
        setEdited(false);
      })
      .catch(reason => {
        setError("user", "Invalid URL key!");
      });
  };


  return (
    <div className="content">
      <header>
        <h2>{t('title')}</h2>
        <Button 
          size="small"
          icon={<FontAwesomeIcon icon="plus"/>} 
          onClick={handleNewClick} />
      </header>
      <div className="rangeCheck">
        <div className="container">
          <header>
            <div>
              {t("range.count")}
            </div>
            <div>
              {t("range.first")}
            </div>
            <div>
              {t("range.last")}
            </div>
          </header>
          {items.map((item, key) => (
            <div className="item" key={key}>
              <label>{t("range.count")}</label>
              <Input value={item} onInput={(e: FormEvent<HTMLInputElement>) => updateValue(key, (e.target as HTMLInputElement).value)} />
              <label>{t("range.first")}</label>
              <span>{response?.ranges?.[key] ? response.ranges[key].first : "????????"}</span>
              <div>-</div>
              <label>{t("range.last")}</label>
              <span>{response?.ranges?.[key] ? response.ranges[key].last : "????????"}</span>
              {items.length > 1 && <Button 
                size="large"
                mode="link"
                type="button"
                color="danger"
                onClick={() => deleteOne(key)}
                icon={<FontAwesomeIcon icon="times"/>} />}
            </div>
          ))}
        </div>
        {edited && <div className="container">
          <Button 
            size="large" 
            type="button"
            onClick={() => submit()}
            icon={loading ? <Spinner><LoaderIcon color='white'/></Spinner> : <FontAwesomeIcon icon="arrow-right"/>}>
            {t("submit")}
          </Button>
        </div>}
      </div>
    </div>
  );
}
