import { RemoteList } from '../../Components/RemoteList/RemoteList';
import { useGetSummaryApi } from '../../Utilities/Api';
import './Scans.scss';

export default function Summary()
{
  const { response } = useGetSummaryApi();

  if(!response) return <></>;

  return (
    <div className="scans">
      <div className='tile'>
        <h3>Počty scanů</h3>
        <div className='subtitle'>z různých IP</div>
        <RemoteList endpoint="get_scans" params={{ scope: "ip" }} columns={["code", "count"]} />
      </div>
      <div className='tile'>
        <h3>Počty scanů</h3>
        <div className='subtitle'>z různých měst</div>
        <RemoteList endpoint="get_scans" params={{ scope: "city" }} columns={["code", "count"]} />
      </div>
    </div>
  );
}
