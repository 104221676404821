import { Store } from "../../../Utilities/Api";

type Props = {
  count: number,
  onClick: () => void
} & Store

export default function Item({ count, onClick, ...props }: Props)
{
  return (
    <div className="item">
      <header onClick={onClick}>
        <div className="icon">
          <img src={props.logo} alt={""} />
        </div>
        <h2>{props.name} ({count})</h2>
      </header>
    </div>
  );
}