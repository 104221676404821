import { useApi } from "../Api"
import { Store } from "../Types/Store";

type Props = Omit<Omit<Store, "id">, "logo"> & { logo?: File };

type Response = {}

export const useNewStoreApi = () => {
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => 
        sendBase<Response>("new_store", "POST", props);

    return {
        loading,
        send
    };
}