import settings from "./settings.json"
import login from "./login.json"
import summary from "./summary.json"
import nav from "./nav.json"
import orders from "./orders.json"
import locations from "./locations.json"
import locationSuggestions from "./location-suggestions.json"
import stores from "./stores.json"
import rangeCheck from "./range-check.json"
import rangeGenerate from "./range-generate.json"
import errors from "./errors.json"
import ui from "./ui.json"

export default { settings, login, summary, nav, orders, locations, locationSuggestions, stores, rangeCheck, rangeGenerate, errors, ui }