import { useTranslation } from 'react-i18next';
import './Error.scss';
// @ts-ignore
// import character from './character-sad.svg';

type Code = "404"

type Props = {
    code: Code
}

export default function Error({ code }: Props)
{
    const { t } = useTranslation("errors");
    const errors = {
        "404": t("404")
    }

    return (
        <div className='error'>
           {/* <img src={character} alt="" className="character" /> */}
           <div className='title'>Error {code}</div>
           <div className='message'>{errors[code]}</div>
        </div>
    );
}
