import { FlagCZIcon, FlagENIcon, Select } from "../../../Components/UI";
import { useSettings, LanguageType, ThemeType } from "../../../Utilities/Settings/UseSettings";
import { useTranslation } from "react-i18next";

export default function View()
{
  const { t } = useTranslation("settings");
  const settings = useSettings();

  const { getLanguage, theme, setLanguage, setTheme } = settings;
  const language = getLanguage();

  const languageOptions = [
    {
      value: "en",
      content: <div><FlagENIcon /> {t("view.language.en")}</div>
    },
    {
      value: "cs",
      content: <div><FlagCZIcon /> {t("view.language.cs")}</div>
    }
  ];

  const themeOptions = [
    {
      value: "light",
      content: <div>🌞 {t("view.colorTheme.light")}</div>
    },
    {
      value: "dark",
      content: <div>🌑 {t("view.colorTheme.dark")}</div>
    }
  ];

  return (
    <div className="user">
      <div className="section">
        <h3>{t("view.language.title")}</h3>
        <form>
          <Select
            value={language} 
            options={languageOptions}
            onChange={(value: string) => setLanguage(value as LanguageType)}/>
        </form>
      </div>
      <div className="section">
        <h3>{t("view.colorTheme.title")}</h3>
        <form>
          <Select
            value={theme} 
            options={themeOptions}
            onChange={(value: string) => setTheme(value as ThemeType)}/>
        </form>
      </div>
    </div>
  );
}
